/* banner start */
.banner {
  position: relative;
  background: url(../img/banner-image.jpg) no-repeat 50% 100% fixed;
  background-size: cover;
  @media @tablet, @mobile {
    background-attachment: scroll;
  }
  @media @tablet-portrait, @mobile {
    height: 33rem;
  }
  @media @mobile-xs {
    height: 40rem;
  }
  .banner-wave {
    width:100%;
    height: auto;
    position: relative;
    z-index: 2;
    @media @tablet-portrait, @mobile {
      position: absolute;
      bottom: -1px;
      left:0;
    }
  }
  .text {
    color:@white;
    .full;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    @media @mobile-xs {
      align-items: flex-start;
    }
    .text-inner {
      width:45%;
      .font-size(25);
      .line-height(40);
      @media @tablet {
        .font-size(20);
        .line-height(30);
        width:40%;
        margin: 3rem 0 0;
      }
      @media @tablet-portrait, @mobile {
        margin: 0 0 2rem;
      }
      @media @mobile {
        width:53%;
        .font-size(18);
        .line-height(28);
      }
      @media @mobile-xs {
        width:100%;
        margin: 7rem 0 0;
      }
      strong {
        font-weight: 700;
      }
    }
    h2 {
      .ttu;
      font-weight: 700;
      .font-size(50);
      .line-height(60);
      .margin(0,0,35,0);
      @media @tablet {
        .font-size(40);
        .line-height(50);
      }
      @media @tablet-portrait, @mobile {
        .font-size(36);
        .line-height(50);
        .margin(0,0,20,0);
      }
      @media @mobile-xs {
        .font-size(30);
        .line-height(40);
        .margin(0,0,15,0);
      }
    }
    p {
      .margin(0,0,40,0);
      @media @tablet-portrait, @mobile {
        .margin(0,0,25,0);
      }
    }
    .btn {
      .margin(0,16,0,0);
    }
  }
  .banner-left {
    position: absolute;
    left:0;
    width:14.19%;
    bottom: -24%;
    height: auto;
    z-index: 3;
    @media @tablet, @mobile {
      display: none;
    }
  }
  .banner-right {
    position: absolute;
    right:0;
    width: 47.4%;
    bottom: -12%;
    height: auto;
    z-index: 3;
    @media @mobile-xs {
      width: 78.4%;
    }
  }
  .images {
    position: absolute;
    bottom: 12%;
    right:10%;
    width:41.35%;
    z-index: 4;
    @media  @mobile-xs {
      bottom: 2%;
      right: 13%;
      width: 79.35%;
    }
    .image-back {
      display: block;
      width:100%;
      height: auto;
    }
    .image {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: auto;
    }
  }
}
/* banner end */

/* about-block start */
.about-block {
  .font-size(18);
  .line-height(36);
  .padding(150,0,0,0);
  position: relative;
  z-index: 10;
  overflow: hidden;
  @media @tablet-portrait, @mobile {
    padding: 4.375rem 0 0;
  }
  @media @mobile-xs {
    padding: 5.375rem 0 4rem;
  }
  h2 {
    color:@primary;
    .ttu;
    .font-size(36);
    .line-height(40);
    .margin(0,0,40,0);
    font-weight: 700;
    @media @mobile-xs {
      .margin(0,0,20,0);
    }
  }
  p {
    .margin(0,0,30,0);
  }
  .image {
    .width(744);
    position: relative;
    .margin(-38,0,0,30);
    @media @tablet, @mobile {
      margin-left: 1rem;
      width:38rem;
    }
    @media @tablet-portrait, @mobile {
      margin: 0 auto;
    }
    @media @mobile-xs {
      width:100%;
      margin-top: 1rem;
    }
    img {
      width:100%;
      height: auto;
      display: block;
    }
    .image {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: auto;
    }
    .slogan {
      position: absolute;
      color:@primary;
      .ttu;
      .font-size(36);
      .line-height(40.4);
      .text-right;
      .bottom(208);
      .right(-38);
      left:auto!important;
      top:auto!important;
      z-index: 5;
      @media @tablet, @mobile {
        .font-size(30);
        .line-height(34.4);
        bottom: 11rem;
        right: -0.375rem;
      }
      @media @tablet-portrait, @mobile {
        bottom: 9rem;
      }
      @media @mobile-xs {
        position: static!important;
        width:100%;
        text-align: center;
        margin-top: 0.5rem;
      }
    }
  }
}
/* about-block end */

/* discus-block start */
.discus-block-holder {
  overflow: hidden;
  padding: 0 0 2.9375rem;
  margin: -18% 0 0;
  @media @tablet {
    margin: -16% 0 0;
  }
}
.discus-block {
  background: url(../img/discus-bg.jpg) no-repeat 50% 100% fixed;
  background-size: cover;
  position: relative;
  padding: 19% 0 0;
  @media @tablet, @mobile {
    background-attachment: scroll;
  }
  .discus-top,
  .discus-bottom {
    position: absolute;
    left:0;
    top:-1px;
    width:100%;
    height: auto;
    z-index: 2;
  }
  .discus-bottom {
    top:auto;
    bottom:-1px;
  }
  .discus-left-side {
    position: absolute;
    left:-10%;
    .bottom(96);
    width:100%;
    .height(889);
    background: url(../img/discus-left-side.png) no-repeat 0 50%;
    background-size: auto 100%;
    z-index: 1;
    @media @tablet, @mobile {
      display: none;
    }
  }
  .discus-right-top {
    position: absolute;
    right:0;
    .top(0);
    .width(202);
    height:100%;
    background: url(../img/discus-right-top.png) no-repeat;
    background-size: 100% auto;
    z-index: 1;
    @media @mobile-xs {
      width: 7.625rem;
    }
  }
  .discus-right-bottom {
    position: absolute;
    left:10%;
    .bottom(-47);
    width:100%;
    .height(679);
    background: url(../img/discus-right-bottom.png) no-repeat;
    background-size: auto 100%;
    z-index: 3;
    @media @tablet, @mobile {
      display: none;
    }
  }
  .discus-inner {
    .height(890);
    .padding(124,0,0,0);
    position: relative;
    z-index: 4;
    @media @tablet, @mobile {
      height: 45.625rem;
    }
    @media @mobile-xs {
      height: auto;
      padding: 3.75rem 0;
    }
    .devices {
      .width(1131);
      position: relative;
      .margin(0,0,0,-192);
      @media @tablet {
        margin: 3rem 0 0 -2rem;
        width: 55.6875rem;
      }
      @media @tablet-portrait {
        margin: 10rem 0 0 -1rem;
        width: 37.6875rem;
      }
      @media @mobile {
        margin: 10rem 0 0 -1rem;
        width: 33.6875rem;
      }
      @media @mobile-xs {
        width:100%;
        margin: 0;
      }
      img {
        width:100%;
        height: auto;
      }
      //.image {
      //  position: absolute;
      //  left:0;
      //  top:0;
      //  width:100%;
      //  height: auto;
      //}
    }
    .text-block {
      position: absolute;
      .right(-48);
      .top(176);
      .text-center;
      z-index: 2;
      @media @tablet, @mobile {
        right: 0rem;
        top: 5rem;
      }
      @media @mobile-xs {
        position: static;
        margin: 0 0 2rem;
      }
      .circle-area {
        .width(382);
        .height(382);
        position: relative;
        .margin(0,0,90,0);
        @media @tablet {
          flex-shrink: 0;
          .margin(0,0,85,0);
        }
        @media @mobile-xs {
          margin: 0 auto 4rem;
        }
        .circle {
          width:100%;
          height: 100%;
          background: @secondary;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color:@white;
          .font-size(18);
          .line-height(36);
          .padding(40,40,40,40);
          overflow: hidden;
          box-shadow: 0 0.625rem 3.375rem rgba(0,0,0,.63);
          z-index: 2;
          position: relative;
        }
        .round {
          z-index: 1;
          position: absolute;
          left: 50%;
          top: 50%;
          border-radius: 50%;
          &.round-1 {
            .width(418);
            .height(418);
            .margin(-209,0,0,-209);
            border: 0.250rem solid @secondary;
            box-shadow: 0 2.625rem 5.063rem rgba(0, 0, 0, 0.3);
            animation-delay: 0.1s;
            opacity: 0;
          }

          &.round-2 {
            .width(450);
            .height(450);
            .margin(-225,0,0,-225);
            border: 0.125rem solid @secondary;
            box-shadow: 0 2.625rem 5.063rem rgba(0, 0, 0, 0.3);
            animation-delay: 0.1s;
            opacity: 0;
          }
        }
      }
    }
  }
}
/* discus-block end */

/* features-block start */
.features-block {
  .padding(95,0,110,0);
  .font-size(18);
  .line-height(36);
  @media @tablet-portrait, @mobile {
    padding: 1.9375rem 0 9.875rem;
  }
  @media @mobile-xs {
    padding-top: 0;
  }
  h2 {
    color:@primary;
    .ttu;
    .font-size(36);
    .line-height(40);
    .margin(0,0,40,0);
    font-weight: 700;
    @media @mobile-xs {
      .margin(0,0,25,0);
    }
  }
  ul {
    list-style: none;
    li {
      position: relative;
      .padding(0,0,0,33);
      &:before {
        content: '';
        position: absolute;
        left:0;
        .top(16);
        .width(5);
        .height(5);
        border-radius: 50%;
        background: @text;
      }
    }
  }
  .circle-block {
    .width(230);
    .height(230);
    position: relative;
    .margin(170,0,0,0);
    margin-left: auto;
    margin-right: auto;
    @media @mobile-xs {
      .width(160);
      .height(160);
    }
    .circle-main {
      width:100%;
      height: 100%;
      background: @secondary;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 5;
      img {
        .width(91);
        height: auto;
        .margin(0,0,5,0);
        @media @mobile-xs {
          width: 3.6875rem;
        }
      }
      span {
        font-weight: 700;
        color:@white;
      }
    }
    .round {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      &.round-1 {
        .width(280);
        .height(280);
        .margin(-140,0,0,-140);
        box-shadow: 0.563rem 1.250rem 3.375rem rgba(0, 0, 0, 0.24);
        @media @mobile-xs {
          .width(210);
          .height(210);
          .margin(-105,0,0,-105);
        }
      }
      &.round-2 {
        .width(370);
        .height(370);
        .margin(-185,0,0,-185);
        box-shadow: 0.563rem 1.250rem 3.375rem rgba(0, 0, 0, 0.24);
        @media @mobile-xs {
          .width(300);
          .height(300);
          .margin(-150,0,0,-150);
        }
      }
      &.round-3 {
        .width(450);
        .height(450);
        .margin(-225,0,0,-225);
        box-shadow: 0.563rem 1.250rem 3.375rem rgba(0, 0, 0, 0.24);
        @media @mobile-xs {
          .width(380);
          .height(380);
          .margin(-190,0,0,-190);
        }
      }
    }
    .item {
      position: absolute;
      border-radius: 50%;
      background: fade(@primary, 94%);
      color:@white;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0.563rem 1.250rem 3.375rem rgba(0, 0, 0, 0.24);
      .font-size(15);
      .line-height(25);
      .padding(10,10,10,10);
      .text-center;
      z-index: 4;
      &.item-1 {
        .width(90);
        .height(90);
        .left(-44);
        .top(-87);
      }
      &.item-2 {
        .width(116);
        .height(116);
        .right(-76);
        .top(-108);
      }
      &.item-3 {
        .width(150);
        .height(150);
        .right(-188);
        .bottom(-29);
        @media @mobile-xs {
          .width(135);
          .height(135);
          .right(-148);
        }
      }
      &.item-4 {
        .width(116);
        .height(116);
        .right(54);
        .bottom(-132);
      }
      &.item-5 {
        .width(110);
        .height(110);
        .left(-108);
        .bottom(-19);
        @media @mobile-xs {
          .left(-120);
        }
      }
    }
  }
}
/* features-block end */

/* get-start-block start */
.get-start-block {
  .padding(47,0,0,0);
  overflow: hidden;
  .get-start-block-inner {
    color:@white;
    .font-size(25);
    .line-height(30);
    .height(460);
    display: flex;
    align-items: center;
    .text-center;
    background: url(../img/get-start-bg.jpg) no-repeat 50% 100% fixed;
    background-size: cover;
    position: relative;
    @media @tablet, @mobile {
      background-attachment: scroll;
    }
    @media @tablet-portrait, @mobile {
      height: 20.75rem;
      .font-size(20);
      .line-height(25);
    }
    @media @mobile-xs {
      .font-size(18);
      .line-height(22);
      height: 17.75rem;
    }
  }
  h3 {
    .ttu;
    .font-size(50);
    .line-height(60);
    .margin(0,0,15,0);
    font-weight: 700;
    @media @tablet-portrait, @mobile {
      .font-size(40);
      .line-height(50);
    }
    @media @mobile-xs {
      .font-size(30);
      .line-height(40);
      .margin(0,0,10,0);
    }
  }
  p {
    .margin(0,0,40,0);
    @media @mobile-xs {
      .margin(0,0,25,0);
    }
  }
  .image-right {
    position: absolute;
    right: 0;
    bottom:0;
    .width(155);
    height: auto;
    @media @mobile-xs {
      width: 4.6875rem;
    }
  }
  .image-left {
    position: absolute;
    left: 0;
    .top(-47);
    .width(249);
    height: auto;
    @media @mobile-xs {
      top: -1.9375rem;
      width: 6.5625rem;
    }
  }
}
/* get-start-block end */

