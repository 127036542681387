.footer {
  background: @white;
  .padding(36,0,22,0);
  color:@text;
  a {
    color:@text;
    &:hover {
      text-decoration: none;
      color:@secondary;
    }
  }
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .font-size(15);
    .line-height(25);
    .padding(0,0,20,0);
    @media @tablet-portrait, @mobile {
      flex-direction: column;
    }
    .left-side {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @media @tablet-portrait, @mobile {
        justify-content: space-between;
        width:100%;
        padding: 0 0 2rem;
      }
      @media @mobile-xs {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text-center;
      }
      .logo-hold {
        .width(280);
        @media @mobile-xs {
          width:100%;
        }
      }
      .logo {
        .width(184);
        height: auto;
        display: block;
        @media @mobile-xs {
          margin: 0 auto;
        }
      }
      .address {
        .width(200);
        @media @mobile-xs {
          width:100%;
          margin: 1rem 0;
        }
      }
      .contact {
        .width(280);
        @media @mobile-xs {
          width:100%;
        }
      }
      p {
        margin: 0;
      }
    }
    .right-side {
      .store-links {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        .margin(0,0,25,0);
        @media @tablet-portrait, @mobile {
          justify-content: center;
        }
        a {
          .margin(0,0,0,20);
          .height(36);
          @media @tablet-portrait, @mobile {
            justify-content: center;
            .margin(0,10,0,10);
          }
          img {
            display: block;
            width:auto;
            height: 100%;
          }
        }
      }
      .sub-menu {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 0;
        @media @tablet-portrait, @mobile {
          justify-content: center;
        }
        li {
          position: relative;
          .font-size(13);
          .line-height(16);
          .padding(0,0,0,15);
          list-style: none;
          &:before {
            content: '|';
            position: absolute;
            .left(5);
            top:0;
          }
          &:first-child {
            padding: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    @media @mobile-xs {
      flex-direction: column;
    }
  }
  .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media @mobile-xs {
      margin: 0 0 1.5rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      .width(40);
      .height(40);
      border-radius: 50%;
      color:@primary;
      .margin(0,0,0,22);
      border:1px solid @primary;
      @media @mobile-xs {
        .margin(0,11,0,11);
      }
      &:hover {
        text-decoration: none;
        color:@secondary;
        border-color:@secondary;
      }
      i {
        .font-size(18.67);
      }
    }
  }
  .copy {
    display: block;
    .text-center;
    .font-size(13);
  }
}

