/* round-animation start */
@-webkit-keyframes round-animation {
  from {
    opacity: 0.1;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes round-animation {
  from {
    opacity: 0.1;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.round-animation {
  -webkit-animation-name: round-animation;
  animation: round-animation 2.0s infinite ease;
}
/* round-animation end */

/* pulse-round start */
@keyframes pulse-round {
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.0);
  }
  90% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.0);
  }
}
@-webkit-keyframes pulse-round {
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.0);
  }
  90% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.0);
  }
}
.pulse-round {
  -webkit-animation-name: pulse-round;
  animation: pulse-round 2.5s infinite ease-in-out;
}
/* pulse-round end */

/* loader start */

@-webkit-keyframes ball-pulse-rise-even {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  25% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  75% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: scale(1);
    transform: scale(1); }
}

@keyframes ball-pulse-rise-even {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  25% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  75% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: scale(1);
    transform: scale(1); }
}

@-webkit-keyframes ball-pulse-rise-odd {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  25% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  75% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
}

@keyframes ball-pulse-rise-odd {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  25% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  75% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
}
/* loader end */