.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: @text;
	min-height: 100vh;
	background: @white;
	//overflow-x: hidden;
	.OS;
	line-height: 1.1;
	@media (min-width:1441px) {
		font-size: 16px;
	}
	@media (max-width:1440px) {
		font-size: 14px;
	}
	@media (max-width:1280px) {
		font-size: 12px;
	}
	@media (max-width:1024px) {
		font-size: 13px;
	}
}
img {
	vertical-align: top;
}
a {
	color: @primary;
	text-decoration: none;
	&:hover {
		color: @primary;
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.no-scroll {
	@media @mobile {
		position: fixed;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
}
.container {
	max-width: 74.125rem;
}
.btn {
	.inline-b;
	.line-height(48);
	background: @primary;
	color:@white;
	.font-size(16);
	.OS;
	font-weight: 700;
	border: 1px solid @primary;
	cursor: pointer;
	border-radius: 1.563rem;
	.padding(0,36,0,36);
	.transition(all 0.1s ease);
	outline: 0;
	white-space: nowrap;
	&:hover {
		border-color:@primary;
		background: fade(@primary, 90%);
		color:@white;
		text-decoration: none;
	}
	&:focus {
		box-shadow:none;
		color:@white;
	}
	&.btn-secondary {
		background: @secondary;
		border-color:@secondary;
		&:hover {
			background: fade(@secondary, 80%);
		}
	}
	&.btn-outline-primary {
		background: none;
		color:@primary;
		border-color:@primary;
		&:hover {
			background: fade(@primary, 10%);
		}
	}
	&.btn-outline-secondary {
		background: none;
		color:@secondary;
		border-color:@secondary;
		&:hover {
			background: fade(@secondary, 10%);
		}
	}
	&.btn-outline-white {
		background: none;
		color:@white;
		border-color:@white;
		&:hover {
			background: fade(@white, 10%);
		}
	}
}


/* loader start */
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: rgba(255,255,255,1);
}
.ball-pulse-rise {
	.width(120);
}
.ball-pulse-rise > div:nth-child(2n) {
	-webkit-animation-name: ball-pulse-rise-even;
	animation-name: ball-pulse-rise-even;
}
.ball-pulse-rise > div:nth-child(2n-1) {
	-webkit-animation-name: ball-pulse-rise-odd;
	animation-name: ball-pulse-rise-odd;
}
.ball-pulse-rise > div {
	background-color: @secondary;
	.width(15);
	.height(15);
	border-radius: 100%;
	.margin(2,2,2,2);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: cubic-bezier(0.15, 0.46, 0.9, 0.6);
	animation-timing-function: cubic-bezier(0.15, 0.46, 0.9, 0.6);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

/* loader end */



@media @tablet-portrait, @mobile {
	//.wow {
	//	visibility: visible !important;
	//	-webkit-animation: none !important;
	//	-moz-animation: none !important;
	//	-o-animation: none !important;
	//	-ms-animation: none !important;
	//	animation: none !important;
	//}
}
