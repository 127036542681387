.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	.transition(all 0.3s ease);
	display: flex;
	justify-content: space-between;
	align-items: center;
	.height(80);
	&.slicked {
		background: @primary;
	}
	.logo {
		.width(201);
		flex-shrink: 0;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.header-inner {
		display: flex;
		//justify-content: space-between;
		justify-content: flex-end;
		align-items: center;
		width:100%;
		@media @tablet, @mobile {
			justify-content: flex-end;
		}
	}
	.nav-holder {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@media @mobile {
			position: fixed;
			right:-100%;
			//right:0;
			top:0;
			height: 100%;
			margin: 0;
            width:100%;
			.padding(80,0,0,0);
			background: @primary url(../img/get-start-right.png) no-repeat 100% 100%;
            background-size: 7rem auto;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			.transition(all 0.5s ease);
			&.show {
              right:0;
			}
		}
		.nav-logo {
			display: none;
			position: absolute;
			top:1rem;
			left:1rem;
			width:9rem;
			@media @mobile {
				display: block;
			}
		}
	}
	ul {
		display: flex;
		justify-content: space-around;
		align-items: center;
		list-style: none;
		margin: 0;
		height: 2.5rem;
		@media @mobile {
			height: 100%;
			width:100%;
			overflow: auto;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			display: block;
		}
		li {
			.font-size(16);
			font-weight: 700;
			.padding(0,20,0,20);
			@media @mobile {
				padding: 0;
				width:100%;
				border-top:1px solid rgba(255,255,255,0.1);
				&:last-child {
					border-bottom:1px solid rgba(255,255,255,0.1);
				}
			}
			a {
				color:@white;
				@media @mobile {
					display: block;
					padding: 1.5rem 2rem;
					text-align: left;
				}
				&:hover,
				&.current {
					text-decoration: none;
					color:@secondary;
				}
			}
			&.active {
				a {
					text-decoration: none;
					color:@secondary;
				}
			}
		}
	}
	.phone {
		.font-size(16);
		color:@secondary;
		white-space: nowrap;
		&:hover {
			text-decoration: none;
			color:@white;
		}
      @media @mobile {
        //margin: 0 2rem 0 0;
        margin: 0 0.5rem 0 0;
      }
		@media @mobile-xs {
			border-radius: 50%;
			background: @secondary;
			color:@white;
			width:2.5rem;
			height: 2.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		i {
			color:@secondary;
			.font-size(16);
			.margin(0,10,0,0);
			display: none;
			@media @mobile-xs {
				display: block;
				margin: 0;
				color:@white;
			}
		}
		span {
			font-weight: 700;
			@media @mobile-xs {
				display: none;
			}
		}
	}
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	.height(2);
	background-color: @secondary;
	content: "";
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	//overflow: hidden;
	position: relative;
	z-index: 10;
	//right:20px;
	//top:12px;
	margin: 0;
	padding: 0;
	.width(26);
	.height(20);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	-webkit-transition: all 0.3s ease 0.3s;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@media @mobile {
		display: block;
	}
	span {
		display: block;
		position: absolute;
		.top(9);
		left: 0;
		right: 0;
		.height(2);
		background: @secondary;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	span::before {
		&:extend(.btn-lines);
		.top(-7);
	}
	span::after {
		&:extend(.btn-lines);
		.bottom(-7);
	}
}
.c-hamburger--htx {
	background-color: transparent;
	&.active {
		&:before {
			display: none;
		}
		span {
			background: none;
		}
		span::before {
			top: 0;
			transform:rotate(45deg);
			-webkit-transform:rotate(45deg);
			//background: @white;
		}
		span::after {
			bottom: 0;
			transform:rotate(-45deg);
			-webkit-transform:rotate(-45deg);
			//background: @white;
		}
	}
}
/*hamburger btn*/
